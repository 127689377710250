import React from 'react';

import css from './MaxWidth.module.css';

interface IProps {
  className?: string;
  collapseMobile?: boolean;
  id?: string;
  style?: React.CSSProperties;
}

const MaxWidth: React.FC<React.PropsWithChildren<IProps>> = ({
  className = '',
  children,
  collapseMobile,
  id,
  style,
}) => {
  const collapseStyle = collapseMobile ? css.collapseMobile : '';
  return (
    <div
      id={id}
      className={`flex flex-col w-full px-4 max-w-xxl ${collapseStyle} ${className}`}
      style={style}>
      {children}
    </div>
  );
};

export default MaxWidth;
